/** @jsx jsx */

import { jsx } from "@emotion/react"
import { Page } from "@src/components"
import { graphql } from "gatsby"

export default ({ data }: { data: any }) => {
  const { title, content } = data.datoCmsHomeModular
  return <Page {...{ title, content, locale: "en", interactiveHeader: true }} />
}

export const query = graphql`
  query HomeEnQuery {
    datoCmsSiteConfig {
      facebookUrl
      faqUrl
      twitterUrl
      voorwaardenUrl
      instagramUrl
      privacyUrl
      ticketsUrl
      ticketsButtonText
    }
    datoCmsHomeModular(locale: { eq: "en" }) {
      title
      content {
        ... on DatoCmsBanner {
          ...BannerFragment
        }
        ... on DatoCmsIntro {
          ...IntroFragment
        }
        ... on DatoCmsDoubleImage {
          ...DoubleImageFragment
        }
        ... on DatoCmsTextBlock {
          ...TextBlockFragment
        }
        ... on DatoCmsTextImageBlock {
          ...TextImageBlockFragment
        }
        ... on DatoCmsTextImageBlockLeft {
          ...TextImageBlockLeftFragment
        }
        ... on DatoCmsQuote {
          ...QuoteFragment
        }
        ... on DatoCmsEventsList {
          ...EventsListFragment
        }
        ... on DatoCmsMagazine {
          ...MagazineFragment
        }
        ... on DatoCmsSocial {
          ...SocialFragment
        }
      }
    }
  }
`
